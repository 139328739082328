import {useTranslation} from "@i18n/client";
import React from "react";

import {Focusable} from "../../../Focusable";

const SearchDialogTrigger = ({onClick}: {onClick: () => void}) => {
  const i18n = useTranslation();
  return (
    <Focusable>
      <button
        id="modal-trigger"
        className="df aic brdn otn bg-gray100 p3 w100p br3 cp mb2"
        onClick={onClick}
        data-cy="care-discovery-search-modal-trigger"
      >
        <span className="cIcon-search mr3 fs26 text-gray600" aria-hidden />
        <span className="gray600 text-left self-center fs16 text-ellipsis font-ir overflow-hidden wsnw lc1 w-full">
          {i18n.t("Search symptoms or services")}
        </span>
      </button>
    </Focusable>
  );
};

export default React.memo(SearchDialogTrigger);
