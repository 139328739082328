import React from "react";

import {useTranslation} from "@i18n/client";

const Error = (): React.ReactElement => {
  const {t} = useTranslation();
  return (
    <div className="h-full w-full py-20 bg-gray-50 rounded-md">
      <div className="flex items-center justify-center flex-col">
        <em className="text-error-800 not-italic text-lg">{t("Something went wrong.")}</em>
        <em className="text-error-800 not-italic text-lg">
          {t("Please refresh or try again later.")}
        </em>
      </div>
    </div>
  );
};

export default React.memo(Error);
