import {colors} from "@c10h/colors";
import {useTranslation} from "@i18n/client";
import React, {useState} from "react";

import useMediaQuery from "../../useMediaQuery";
import {s3ImageSource} from "../../useS3ImgSrc";
import {formatName} from "../../utils/formatName";
import {v5Pages} from "../_common/_constants";
import NextImage from "./NextImage";
import {ExtendedDoctor} from "./OtherProviders";
import {ReadableSlotTime} from "./ReadableSlotTime";
import {createURLBuilder} from "src/utils/urls";
import Link from "next/link";

type Props = {
  doctor: ExtendedDoctor;
};

const OtherProvider: React.FC<Props> = ({
  doctor: {
    slug,
    firstName,
    lastName,
    profileImageId,
    credential,
    specialties,
    nextApptSlot,
    primaryLocationName,
  },
}) => {
  const i18n = useTranslation();
  const isSm = useMediaQuery("sm");
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const imageDimension = isSm ? 250 : 300;
  const formattedName = formatName(firstName, lastName, credential);
  const providerUrl = createURLBuilder(v5Pages.providers).replaceSegment(slug).build();
  return (
    <Link
      href={providerUrl}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <figure
        className="br2"
        style={{outline: `3px solid ${isFocused ? "blue" : "transparent"}`, outlineOffset: 6}}
      >
        <div className="br50 oh df jcc aic" style={{height: imageDimension, width: imageDimension}}>
          <NextImage
            aria-hidden
            alt={formattedName}
            width={imageDimension}
            height={imageDimension}
            quality={80}
            src={s3ImageSource(profileImageId, 3)}
          />
        </div>
        <figcaption className="df fdc aic font-ir">
          <strong
            className="tac fs20 fs18-sm font-csb mt4 mb3 t-100 fsn"
            style={{color: isHovered ? colors.gray400 : colors.gray800}}
          >
            {formattedName}
          </strong>

          <span className="tac fs16 fs14sm mb1 t-100 gray800 lh16">
            {specialties
              .map(s => i18n.t(s))
              .slice(0, 3)
              .join(", ")}
          </span>
          <span className="fs14 tac gray400 mb6">{primaryLocationName}</span>
          {nextApptSlot && (
            <span className="df jcc fs14 fs12-sm fdc-sm aic-sm jsfe font-mb">
              <strong className="gray600 op1 mv0 mr2 mr0-sm fsn ttu">
                {i18n.t("Next available")}
              </strong>
              <em className="darkGreen mv0 mt2-sm op1 fsn ttu">
                <ReadableSlotTime time={nextApptSlot} />
              </em>
            </span>
          )}
        </figcaption>
      </figure>
    </Link>
  );
};

export default OtherProvider;
